.main-sidebar .sidebar {
  overflow-y: scroll;
}

.cursor-pointer{
  cursor: pointer;
}

.loader{
  display: flex;
  justify-content: center;
}

.borderTopNone{
border-top: none !important; 
}

th,td{
  min-width: 140px;
}

.card{
  padding-bottom: 10px !important;
} 
.tableContainer{
  overflow-y: auto !important;
  max-height: 65vh;
}

.paginationBttns{
  width: 100%;
  height: 10px;
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: baseline;
  margin-top: 20px;
  padding-right: 30px !important;

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.paginationBttns a {
  padding: 4px;
  margin: 5px;
  border-radius: 5px;
  border: 1px solid black;
  background-color: black;
  color: white !important;
  cursor: pointer;
  letter-spacing: 0;
  text-decoration: none;
}
.paginationBttns a:hover{
  color: black;
  background-color: slategray;
}
.paginationActive a {
  background-color: black;
  color: white;
  padding-left: 5px;
  cursor: pointer;
  text-shadow:
    0 0 7px #fff,
    0 0 10px #fff,
    0 0 21px #fff,
    0 0 42px #0fa,
    0 0 82px #0fa,
    0 0 92px #0fa,
    0 0 102px #0fa,
    0 0 151px #0fa;
}
.paginationBttnsstatic{
  position: fixed;
  bottom: 10px;
  width: 80%;
  height: 10px;
  list-style: none;
  display: flex;
  justify-content: center;
}
.paginationBttnsstatic a {
  padding: 7px;
  margin: 8px;
  border-radius: 5px;
  border: 1px solid black;
  background-color: black;
  color: white !important;
  cursor: pointer;
  letter-spacing: 0;
  text-decoration: none;
  font-size: 16px;
}